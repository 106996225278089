import React, { useState, useEffect } from 'react';
import Menu from '../Menu';


const CheckShareDetails = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        // Replace with your API endpoint
        

        const mytoken=sessionStorage.getItem('google_token');
        const apiUrl = 'https://app64.heliohost.us/api/checkSharesDetail.php';
        //const apiUrl = 'http://localhost/api/getShareAlarms.php';

        
        fetch( apiUrl,{
            method:"GET",          
            headers:{
                "Content-Type": "application/json",
                "AuthCode" : mytoken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
            });
    }, []); // Empty dependency array means this runs once on component mount

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <Menu/>
            <h1>Data Table</h1>
            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>symb</th>
                        <th>platform</th>
                        <th>nrunits</th>
                        <th>info</th>                        
                        
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.symb}>                           
                            <td>{item.symb}</td>
                            <td>{item.platform}</td>
                            <td>{item.nrunits}</td>
                            <td>{item.info}</td>
                            
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CheckShareDetails