import React, { useState, useEffect } from 'react';
import Menu from '../Menu'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormState } from 'react-dom';


const MyShareDetails = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editRecord, setEditRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const mytoken = sessionStorage.getItem('google_token');
    const { platform,symbol } = useParams();
    const navigate = useNavigate();
    const [filterValue, setFilterValue] = useState(""); // State to hold filter input
    const [symbolSel, setSymbolSel] = useState(""); // State to hold filter input
    const [filterKey, setFilterKey] = useState(""); // Key to filter by (default:
    const [sum, setSum] = useState(0);

    const filteredData = data.filter((item) => {
        if (!filterValue) return true; // If no filter, show all data
        const itemValue = item[filterKey]?.toString().toLowerCase(); // Convert item value to string for comparison
        return itemValue === filterValue.toLowerCase();
    });

    const [selectedValue, setSelectedValue] = useState("");

    const [platforms, setPlatforms] = useState([
        { code: "bttrade", name: "bttrade"},
        { code: "etoro", name: "etoro"},
        { code: "goldring", name: "goldring"},
        { code: "IBKR", name: "IBKR"},
        { code: "trading212", name: "trading212"},
        { code: "XTBEUR", name: "XTBEUR"},
        { code: "XTBUSD", name: "XTBUSD"}
      ]);

    const [formAdd, setFormAdd] = useState({
        symb: '',
        platform: '',
        nrunits: '',
        valnow: '',
        valinvact: '',
        valc: '',
        currency: '',
        symb_platform: '',
        data_ach: ''

    });

    const copyValues = () => {
        setFormAdd(editRecord);
    };

    const deleteRecord = async () => {
        const userConfirmed = window.confirm("Are you sure you want to delete ?");
        if (!userConfirmed) {
            return;
        }
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateMyShareDetails.php?id=${editRecord.id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                }
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
            window.location.reload();

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };




    useEffect(() => {

        console.log(`Parameter changed! Now showing item with ID: ${platform} ${symbol}`);

        // Replace with your API endpoint
        if (typeof symbol !== "undefined") {
            setSymbolSel(symbol)   ;
        }


        var apiUrl = 'https://app64.heliohost.us/api/getMySharesDetails.php';
        if (typeof platform !== "undefined") {
        
            apiUrl = 'https://app64.heliohost.us/api/getMySharesDetails.php?platform=' + platform;
            if (typeof symbol !== "undefined") {
                apiUrl=apiUrl+"&symbol="+symbol;
            }
        }
        //const apiUrl = 'http://localhost/api/getShareAlarms.php';


        fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "AuthCode": mytoken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
                if (typeof platform !== "undefined") {
                    setFilterValue(platform);
                    setFilterKey("platform");

                }

                const totalSum = data.reduce((sum, item) => sum + item.nrunits*item.valnow, 0);
                setSum(totalSum.toFixed(2));
                

            })
            .catch((error) => {
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
            });
    }, []); // Empty dependency array means this runs once on component mount

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }


    const handleEditClick = (record) => {
        setEditRecord(record); // Set the record to be edited
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditRecord({ ...editRecord, [name]: value });
    };

    const handleSymbChange = (e) => {
        setSymbolSel(e.target.value);
    };

    const changePlatform = (e) => {
        navigate(`/mysharesdet/${platform}/${symbolSel}`); 
        window.location.reload();
    };


    
    


    const handleInputChangeAdd = (e) => {
        const { name, value } = e.target;
        setFormAdd({ ...formAdd, [name]: value });
    };


    const handleNavigate = (platform) => {
        navigate(`/mysharesdet/${platform}`);

    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue) {
            if(symbol===""){
            navigate(`/mysharesdet/${selectedValue}`);
            }else{
                navigate(`/mysharesdet/${selectedValue}/${symbolSel}`);
            }
            window.location.reload();
        }

    };


    const handleSave = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            // ?id=${editRecord.id}
            const response = await fetch(`https://app64.heliohost.us/api/updateMyShareDetails.php`, {                
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                },
                body: JSON.stringify(editRecord),
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAdd = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Send POST request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateMyShareDetails.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                },
                body: JSON.stringify(formAdd),
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div>
            <Menu />
            <h1>Data Table</h1>

            {editRecord && (
                <div style={{ marginTop: "20px" }}>
                    <h2>Edit Record</h2>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSave();
                        }}
                    >
                        <div>
                            <label>
                                Symb:
                                <input
                                    type="text"
                                    name="symb"
                                    value={editRecord.symb}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Platform:
                                <input
                                    type="text"
                                    name="platform"
                                    value={editRecord.platform}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Data ach:
                                <input
                                    type="text"
                                    name="data_ach"
                                    value={editRecord.data_ach}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                nrunits:
                                <input
                                    type="text"
                                    name="nrunits"
                                    value={editRecord.nrunits}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>



                        <div>
                            <label>
                                Initial share value:
                                <input
                                    type="text"
                                    name="valinitact"
                                    value={editRecord.valinitact}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                Share value now:
                                <input
                                    type="text"
                                    name="valnow"
                                    value={editRecord.valnow}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>

                        <div>
                            <label>
                                currency:
                                <input
                                    type="text"
                                    name="currency"
                                    value={editRecord.currency}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                IsActive:
                                <input
                                    type="text"
                                    name="isactive"
                                    value={editRecord.isactive}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? "Saving..." : "Save"}
                        </button>
                        <button type="button" onClick={() => setEditRecord(null)}>
                            Cancel
                        </button>
                        <button type="button" onClick={copyValues}>
                            Clone
                        </button>
                        <button type="button" onClick={deleteRecord}>
                            Delete
                        </button>
                    </form>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
            )}


            <div style={{ marginTop: "20px" }}>
                <h2>Add Record</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAdd();
                    }}
                >
                    <div>
                        <label>
                            Symb:
                            <input
                                type="text"
                                name="symb"
                                value={formAdd.symb}
                                onChange={handleInputChangeAdd}

                            />
                        </label>
                    </div>



                    <div>
                        <label>
                            platform: &nbsp;
                            <select name="platform" id="platform-select" value={formAdd.platform} onChange={handleInputChangeAdd}>
                                <option value="-">-</option>
                                {platforms.map((item) => (
                                        <option key={item.code} value={item.code}>
                                            {item.name}
                                        </option>
                                    ))}

                            </select>
                        </label>
                    </div>

                    <div>
                            <label>
                                Data ach:
                                <input
                                    type="text"
                                    name="data_ach"
                                    value={formAdd.data_ach}
                                    onChange={handleInputChangeAdd}
                                />
                            </label>
                        </div>

                    <div>
                        <label>
                            nrunits:
                            <input
                                type="text"
                                name="nrunits"
                                value={formAdd.nrunits}
                                onChange={handleInputChangeAdd}

                            />
                        </label>
                    </div>



                    <div>
                        <label>
                            Initial share value:
                            <input
                                type="text"
                                name="valinitact"
                                value={formAdd.valinitact}
                                onChange={handleInputChangeAdd}

                            />

                        </label>
                    </div>
                    <div>
                        <label>
                            Share value now:
                            <input
                                type="text"
                                name="valnow"
                                value={formAdd.valnow}
                                onChange={handleInputChangeAdd}
                            />

                        </label>
                    </div>

                    <div>
                        <label>
                            currency: &nbsp;
                            <select name="currency" id="currency-select" value={formAdd.currency} onChange={handleInputChangeAdd}>
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="RON">RON</option>
                            </select>
                        </label>
                    </div>

                    <div>
                        <label>
                            symb_platform:
                            <input
                                type="text"
                                name="symb_platform"
                                value={formAdd.symb_platform}
                                onChange={handleInputChangeAdd}
                            />
                        </label>
                    </div>

                    <button type="submit" disabled={isLoading}>
                        {isLoading ? "Adding..." : "Add"}
                    </button>
                    <button type="button" onClick={() => setEditRecord(null)}>
                        Cancel
                    </button>
                </form>
                {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div>
                <br/>
            </div>        
            <div>
                <div>
                    <select name="platformsel" id="platform-select" value={platform} onChange={handleSelectChange}>
                        <option value="">--Select--</option>
                        {platforms.map((item) => (
                                        <option key={item.code} value={item.code}>
                                            {item.name}
                                        </option>
                                    ))}
                    </select>
                    <button type="button" onClick={changePlatform}>
                            Change
                        </button>
                </div>
                <div>
                    <input type='text' value={symbolSel} onChange={handleSymbChange}/><br/>
                </div>

            </div>
            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>symb</th>
                        <th>platform</th>
                        <th>nrunits</th>
                        <th>valnow</th>
                        <th>valinitact</th>
                        <th>valtnow</th>
                        <th>valtinit</th>
                        <th>procent</th>
                        <th>cagr</th>
                        <th>period_month</th>
                        <th>data_ach</th>
                        <th>data_sell</th>
                        <th>currency</th>
                        <th>isactive</th>
                        <th>Modify</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.symb}</td>
                            <td>{item.platform}</td>
                            <td>{item.nrunits}</td>
                            <td>{item.valnow}</td>
                            <td>{item.valinitact}</td>
                            <td>{item.valtnow}</td>
                            <td>{item.valtinit}</td>
                            <td><b>{item.procent}</b></td>
                            <td><b
                            >{item.cagr}</b></td>
                            <td>{item.period_month}</td>
                            <td>{item.data_ach}</td>
                            <td>{item.data_sell}</td>
                            <td>{item.currency}</td>
                            <td>{item.isactive}</td>
                            <td> <button onClick={() => handleEditClick(item)}>Edit</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <b>Total {sum}</b>
            </div>



        </div>
    );
}


export default MyShareDetails