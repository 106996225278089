import React, { useState, useEffect } from 'react';
import Menu from '../Menu'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormState } from 'react-dom';



const MyShares = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editRecord, setEditRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const mytoken = sessionStorage.getItem('google_token');
    const { platform } = useParams();
    const navigate = useNavigate();
    const [filterValue, setFilterValue] = useState(""); // State to hold filter input
    const [filterKey, setFilterKey] = useState(""); // Key to filter by (default:
    const [sum, setSum] = useState(0);

    const filteredData = data.filter((item) => {
        if (!filterValue) return true; // If no filter, show all data
        const itemValue = item[filterKey]?.toString().toLowerCase(); // Convert item value to string for comparison
        return itemValue === filterValue.toLowerCase();
    });

    const [selectedValue, setSelectedValue] = useState("");

    const [formAdd, setFormAdd] = useState({
        symb: '',
        platform: '',
        nrunits: '',
        valnow: '',
        valinvact: '',
        valc: '',
        currency: '',
        symb_platform: ''

    });


    useEffect(() => {

        console.log(`Parameter changed! Now showing item with ID: ${platform}`);

        // Replace with your API endpoint



        var apiUrl = '';
        if (typeof platform !== "undefined") {
            if (platform === "ALL"){
                apiUrl = 'https://app64.heliohost.us/api/getMyShares.php';
                
            }
            else
                apiUrl = 'https://app64.heliohost.us/api/getMyShares.php?platform=' + platform;
        }
        //const apiUrl = 'http://localhost/api/getShareAlarms.php';

        if(apiUrl !== ""){

        fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "AuthCode": mytoken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
                if (typeof platform !== "undefined") {
                    if (platform !== "ALL") {
                    setFilterValue(platform);
                    setFilterKey("platform");
                    }

                }

                const totalSum = data.reduce((sum, item) => sum + item.nrunits * item.valnow, 0);
                setSum(totalSum.toFixed(2));
                console.log("Total sum :" + totalSum);

            })
            .catch((error) => {
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
            });
        }else {
            setData([]);
            setLoading(false);
            setFilterValue("");
        }
    }, []); // Empty dependency array means this runs once on component mount

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }


    const handleEditClick = (record) => {
        setEditRecord(record); // Set the record to be edited
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditRecord({ ...editRecord, [name]: value });
    };

    const handleInputChangeAdd = (e) => {
        const { name, value } = e.target;
        setFormAdd({ ...formAdd, [name]: value });
    };


    const handleNavigate = (platform) => {
        navigate(`/myshares/${platform}`);

    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;

        console.log("Selected Value:", selectedValue);
        if (selectedValue) {
            navigate(`/myshares/${selectedValue}`);
            window.location.reload();
        }

    };


    const handleSave = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateMyShare.php?id=${editRecord.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                },
                body: JSON.stringify(editRecord),
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAdd = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateMyShare.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                },
                body: JSON.stringify(formAdd),
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div>
            <Menu />
            <h1>Data Table</h1>

            <select name="platformsel" id="platform-select" onChange={handleSelectChange}>
                <option value="">--Select--</option>
                <option value="ALL">ALL</option>
                <option value="bttrade">bttrade</option>
                <option value="etoro">etoro</option>
                <option value="goldring">goldring</option>
                <option value="IBKR">IBKR</option>
                <option value="trading212">trading212</option>
                <option value="XTBEUR">XTBEUR</option>
                <option value="XTBUSD">XTBUSD</option>

            </select>

            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>symb</th>
                        <th>platform</th>
                        <th>nrunits</th>
                        <th>valinvact</th>
                        <th>valnow</th>
                        <th>currency</th>
                        <th>valtnow</th>
                        <th>valtinv</th>
                        <th>procent</th>
                        <th>symb_platform</th>
                        <th>isactive</th>
                        <th>Modify</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.symb}</td>
                            <td>{item.platform}</td>
                            <td>{item.nrunits}</td>
                            <td>{item.valinvact}</td>
                            <td>{item.valnow}</td>
                            <td>{item.currency}</td>
                            <td>{item.valtnow}</td>
                            <td>{item.valtinv}</td>
                            <td>{item.procent}</td>
                            <td>{item.symb_platform}</td>                            
                            <td>{item.isactive}</td>
                            <td> <button onClick={() => handleEditClick(item)}>Edit</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <b>Total {sum}</b>
            </div>

            {editRecord && (
                <div style={{ marginTop: "20px" }}>
                    <h2>Edit Record</h2>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSave();
                        }}
                    >
                        <div>
                            <label>
                                Symb:
                                <input
                                    type="text"
                                    name="symb"
                                    value={editRecord.symb}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Platform:
                                <input
                                    type="text"
                                    name="platform"
                                    value={editRecord.platform}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                nrunits:
                                <input
                                    type="text"
                                    name="nrunits"
                                    value={editRecord.nrunits}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>



                        <div>
                            <label>
                                Initial share value:
                                <input
                                    type="text"
                                    name="valinvact"
                                    value={editRecord.valinvact}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                Share value now:
                                <input
                                    type="text"
                                    name="valnow"
                                    value={editRecord.valnow}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                symb_platform:
                                <input
                                    type="text"
                                    name="symb_platform"
                                    value={editRecord.symb_platform}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                currency:
                                <input
                                    type="text"
                                    name="currency"
                                    value={editRecord.currency

                                    }
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                IsActive:
                                <input
                                    type="text"
                                    name="isactive"
                                    value={editRecord.isactive}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? "Saving..." : "Save"}
                        </button>
                        <button type="button" onClick={() => setEditRecord(null)}>
                            Cancel
                        </button>
                    </form>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
            )}


            <div style={{ marginTop: "20px" }}>
                <h2>Add Record</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAdd();
                    }}
                >
                    <div>
                        <label>
                            Symb:
                            <input
                                type="text"
                                name="symb"
                                value={formAdd.symb}
                                onChange={handleInputChangeAdd}

                            />
                        </label>
                    </div>



                    <div>
                        <label>
                            platform: &nbsp;
                            <select name="platform" id="platform-select" value={formAdd.platform} onChange={handleInputChangeAdd}>
                                <option value="-">-</option>
                                <option value="bttrade">bttrade</option>
                                <option value="etoro">etoro</option>
                                <option value="goldring">goldring</option>
                                <option value="IBKR">IBKR</option>
                                <option value="trading212">trading212</option>
                                <option value="XTBEUR">XTBEUR</option>
                                <option value="XTBUSD">XTBUSD</option>
                            </select>
                        </label>
                    </div>

                    <div>
                        <label>
                            nrunits:
                            <input
                                type="text"
                                name="nrunits"
                                value={formAdd.nrunits}
                                onChange={handleInputChangeAdd}

                            />
                        </label>
                    </div>



                    <div>
                        <label>
                            Initial share value:
                            <input
                                type="text"
                                name="valinvact"
                                value={formAdd.valinvact}
                                onChange={handleInputChangeAdd}

                            />

                        </label>
                    </div>
                    <div>
                        <label>
                            Share value now:
                            <input
                                type="text"
                                name="valnow"
                                value={formAdd.valnow}
                                onChange={handleInputChangeAdd}
                            />

                        </label>
                    </div>

                    <div>
                        <label>
                            currency: &nbsp;
                            <select name="currency" id="currency-select" value={formAdd.currency} onChange={handleInputChangeAdd}>
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="RON">RON</option>
                            </select>
                        </label>
                    </div>

                    <div>
                        <label>
                            symb_platform:
                            <input
                                type="text"
                                name="symb_platform"
                                value={formAdd.symb_platform}
                                onChange={handleInputChangeAdd}
                            />
                        </label>
                    </div>

                    <button type="submit" disabled={isLoading}>
                        {isLoading ? "Adding..." : "Add"}
                    </button>
                    <button type="button" onClick={() => setEditRecord(null)}>
                        Cancel
                    </button>
                </form>
                {error && <p style={{ color: "red" }}>{error}</p>}
            </div>


        </div>
    );
}

export default MyShares