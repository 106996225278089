import React, { useState, useEffect } from 'react';
import Menu from '../Menu'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";



const ShareAlarmEdit = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [editRecord, setEditRecord] = useState(null);

    const [error, setError] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    
    const mytoken=sessionStorage.getItem('google_token');


    const handleNavigate = (id) => {
        navigate(`/sharealarmedit/${id}`);
    };


    const handleEditClick = (record) => {
        setEditRecord(record); // Set the record to be edited
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditRecord({ ...editRecord, [name]: value });
    };

    const handleSave = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateShareAlarm.php?id=${editRecord.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode" : mytoken
                },
                body: JSON.stringify(editRecord),
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
            window.location.reload();
            
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    const deleteRecord = async (record) => {
        const userConfirmed = window.confirm("Are you sure you want to delete "+record.id+" ?");
        if (!userConfirmed) {
            return;
        }
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateShareAlarm.php?id=${record.id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                }
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
            window.location.reload();

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const addRecord = async (record) => {       
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateShareAlarm.php?id=0`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode" : mytoken
                },
                body: JSON.stringify(editRecord),
            });
            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
            window.location.reload();

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        // Replace with your API endpoint

        
        const mytoken = sessionStorage.getItem('google_token');
        const apiUrl = 'https://app64.heliohost.us/api/getShareAlarm.php?id=' + id;
        //const apiUrl = 'http://localhost/api/getShareAlarms.php';


        fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "AuthCode": mytoken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
            });
    }, []); // Empty dependency array means this runs once on component mount

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }


    return (

        <div>
            <Menu />
            <p>ID1: {id}</p>
            <h1>Data Table</h1>
            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>ID1</th>
                        <th>Symb</th>
                        <th>Val</th>
                        <th>LastVal</th>
                        <th>Typesearch</th>
                        <th>Procent</th>
                        <th>Modify</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (

                        <tr key={item.id}>

                            <td>{item.id}</td>
                            <td>{item.symb}</td>
                            <td>{item.val}</td>
                            <td>{item.lastval}</td>
                            <td>{item.typesearch}</td>
                            <td>{item.procent}</td>
                            <td> <button onClick={() => handleEditClick(item)}>Edit</button></td>
                            <td> <button onClick={() => deleteRecord(item)}>Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Edit Form */}
            {editRecord && (
                <div style={{ marginTop: "20px" }}>
                    <h2>Edit Record</h2>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSave();
                        }}
                    >
                        <div>
                            <label>
                                Name:
                                <input
                                    type="text"
                                    name="symb"
                                    value={editRecord.symb}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Val:
                                <input
                                    type="number"
                                    name="val"
                                    value={editRecord.val}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                       
                        <div>
                            <label>
                            typesearch:
                                <input
                                    list="listSearch"
                                    name="typesearch"
                                    value={editRecord.typesearch}
                                    onChange={handleInputChange}
                                />
                                <datalist id="listSearch">
                                    <option value="LOW" />
                                    <option value="HIGH" />            
                                </datalist>
                            </label>
                        </div>
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? "Saving..." : "Save"}
                        </button>
                        <button type="button" onClick={() => addRecord(null)}>
                            Add
                        </button>
                        <button type="button" onClick={() => setEditRecord(null)}>
                            Cancel
                        </button>
                    </form>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
            )}
        </div>
    )
}

export default ShareAlarmEdit