
import './App.css';

import LoginPage from './LoginPage';
import Logout from './Logout';
import MainPage from './MainPage';
import About from './components/About';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes and Route
import ShareAlarms from './components/ShareAlarms';
import MainGoogle from './components/MainGoogle';
import ShareAlarmEdit from './components/ShareAlarmEdit';
import CheckShareDetails from './components/CheckShareDetails';
import MyShares from './components/MyShares';
import MyCosts from './components/MyCosts';
import MyShareDetails from './components/MyShareDetails';
import ReportMySharesTot from './components/ReportMySharesTot';
function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<MainPage/>} />
      <Route path="/login" element={<LoginPage/>} />      
      <Route path="/logout" element={<Logout/>} />      
      <Route path="/about" element={<About/>} />      
      <Route path="/sharealarms" element={<ShareAlarms/>} /> 
      <Route path="/sharealarmedit/:id" element={<ShareAlarmEdit/>} /> 
      <Route path="/checksharedetails" element={<CheckShareDetails/>} />       
      <Route path="/loginGoogle" element={<MainGoogle/>} />  
      <Route path="/myshares/:platform" element={<MyShares/>} />  
      <Route path="/myshares" element={<MyShares/>} />        
      <Route path="/mycosts/:category?" element={<MyCosts/>} />  
      <Route path="/mysharesdet/:platform/:symbol?" element={<MyShareDetails/>} />  
      <Route path="/mysharesdet" element={<MyShareDetails/>} />  
      <Route path="/reportTotal" element={<ReportMySharesTot/>} />  
      
      
    </Routes>
  </Router>
  
  );
}

export default App;
