import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

function Menu() {
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const [isExpenseMenuOpen, setIsExpenseMenuOpen] = useState(false);

  // Toggle submenu visibility
  const toggleServicesMenu = () => { 
    setIsServicesMenuOpen(!isServicesMenuOpen);
    setIsExpenseMenuOpen(false);

  }
  const toggleExpensesMenu = () => {
    setIsExpenseMenuOpen(!isExpenseMenuOpen);
    setIsServicesMenuOpen(false);

  }

  return (
    <nav className="navbar">
      <ul className="menu-list">
        <li className="menu-item"><Link to="/" className="link">Home</Link></li>
        
        <li className="menu-item"><Link to="/about" className="link">About</Link></li>
        <li className="menu-item"><Link to="/contact" className="link">Contact</Link></li>
        
        {/* Main menu item with a submenu */}
        <li className="menu-item">
          <button
            className="link"
            onClick={toggleServicesMenu}
          >
            Shares
          </button>
          {isServicesMenuOpen && (
            <ul className="submenu">
              <li className="submenu-item"><Link to="/myshares" className="link">My Shares</Link></li>
              <li className="submenu-item"><Link to="/mysharesdet" className="link">My Shares Details</Link></li>
              <li className="submenu-item"><Link to="/sharealarms" className="link">Share Alarms</Link></li>
              <li className="submenu-item"><Link to="/checksharedetails" className="link">Check Share Details</Link></li>
              <li className="submenu-item"><Link to="/reportTotal" className="link">Report total</Link></li>
              <li className="submenu-item"><Link to="/logout" className="link">Logout</Link></li>
            </ul>
          )}
        </li>

          {/* Main menu item with a submenu */}
          <li className="menu-item">
          <button
            className="link"
            onClick={toggleExpensesMenu}
          >
            Expenses
          </button>
          {isExpenseMenuOpen && (
            <ul className="submenu">
              <li className="submenu-item"><Link to="/mycosts" className="link">My Costs</Link></li>              
            </ul>
          )}
        </li>

      </ul>
    </nav>
  );
}

export default Menu;
