import React, { useState, useEffect } from 'react';
import Menu from '../Menu'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";



const MyCosts = () => {

    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editRecord, setEditRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const mytoken = sessionStorage.getItem('google_token');
    const { category } = useParams();
    const navigate = useNavigate();
    const [filterValue, setFilterValue] = useState(""); // State to hold filter input
    const [filterKey, setFilterKey] = useState(""); // Key to filter by (default:
    const [selectedCategory, setSelectedCategory] = useState("");
    const [dateYMD, setDateYMD] = useState('');



    const filteredData = data.filter((item) => {
        if (!filterValue) return true; // If no filter, show all data
        const itemValue = item[filterKey]?.toString().toLowerCase(); // Convert item value to string for comparison
        return itemValue === filterValue.toLowerCase();
    });

    const [form2, setForm2] = useState({
        data_op: '',
        amount: '',
        categ_name: '',
        categ_id: '',
        categ_dest: '',
        details: '',
        subcateg: '',
        card: ''

    });

    const copyValues = () => {
        setForm2(editRecord);
    };



    const deleteRecord = async () => {
        const userConfirmed = window.confirm("Are you sure you want to delete ?");
        if (!userConfirmed) {
            return;
        }
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateMyCosts.php?id=${editRecord.id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                }
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
            window.location.reload();

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    const clearAddValues = () => {

        setEditRecord(null);
        //copyValues();
        setForm2(editRecord);

    };

    const getMyCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };


    const [selectedValue, setSelectedValue] = useState("");



    useEffect(() => {

        //console.log(`Parameter changed! Now showing item with ID: ${category}`);

        //const controller = new AbortController();
        //const signal = controller.signal;
        // Replace with your API endpoint

        setDateYMD(getMyCurrentDate());

        var apiUrl = 'https://app64.heliohost.us/api/getMyCosts.php?nrdays=60';
        if (typeof category !== "undefined") {
            apiUrl = 'https://app64.heliohost.us/api/getMyCosts.php?limit=50&categ_id=' + category;
        }
        //const apiUrl = 'http://localhost/api/getShareAlarms.php';
        
        
        console.log(`apiUrl: ${apiUrl}`);

        fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "AuthCode": mytoken
            }                        
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                
                console.log(" ok receive data ");
                setData(data);
                setLoading(false);
                if (typeof category !== "undefined") {
                    console.log(" set fileter value  "+category);
                    setFilterValue(category);
                    setFilterKey("categ_id");

                }
              
            })
            .catch((error) => {
                
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
                
            });

        // get Categories 
        apiUrl = "https://app64.heliohost.us/api/getMyCostCategories.php";
        fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "AuthCode": mytoken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((categories) => {
                setCategories(categories);
                setLoading(false);
                if (typeof category !== "undefined") {
                    setFilterValue(category);
                    setFilterKey("category");

                }

            })
            .catch((error) => {
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
            });
            
            return () => {
                //controller.abort("stop");
                ;
            };


    }, []); // Empty dependency array means this runs once on component mount

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }


    const handleEditClick = (record) => {
        setEditRecord(record); // Set the record to be edited
        //setSelectedCategory(record.categ_id);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditRecord({ ...editRecord, [name]: value });
    };


    const setCurrentDate = (e) => {
        var vdata =dateYMD;
        //alert(vdata);
        setForm2((prevForm) => ({
            ...prevForm,
            data_op: vdata, // Update the data_op field with the current date
        }));

        
       
        
    };


    const handleNavigate = (category) => {
        navigate(`/mycosts/${category}`);

    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;

        console.log("Selected Value:", selectedValue);
        if (selectedValue) {
            navigate(`/mycosts/${selectedValue}`);
            window.location.reload();
        }

    };


    const handleChangeAdd = (e) => {
        const { name, value } = e.target;
        setForm2({ ...form2, [name]: value });
    };

    const handleSave = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateMyCosts.php?id=${editRecord.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                },
                body: JSON.stringify(editRecord),
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAdd = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Send PUT request to update the record
            const response = await fetch(`https://app64.heliohost.us/api/updateMyCosts.php?operation=add&id=${editRecord.id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AuthCode": mytoken
                },
                body: JSON.stringify(form2),
            });

            if (!response.ok) {
                throw new Error("Failed to update the record.");
            }

            const updatedRecord = await response.json();

            // Update the local state
            setData((prevRecords) =>
                prevRecords.map((rec) =>
                    rec.id === updatedRecord.id ? updatedRecord : rec
                )
            );
            setEditRecord(null); // Close the form after saving

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleChangeCategory = (e) => {
        setSelectedCategory(e.target.value);
      };
    return (
        <div>
            <Menu />
            <h1>Data Table</h1>

            {editRecord && (
                <div style={{ marginTop: "20px" }}>
                    <h2>Edit Record</h2>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSave();
                        }}
                    >
                        <div>
                            <label>
                                Data:
                                <input
                                    type="text"
                                    name="data_op"
                                    value={editRecord.data_op}
                                    onChange={handleInputChange}
                                    placeholder="YYYY-MM-DD"
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                amount:
                                <input
                                    type="text"
                                    name="amount"
                                    value={editRecord.amount}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>



                        <div>
                            <label>
                                categ_name:
                                <input
                                    type="text"
                                    name="categ_name"
                                    value={editRecord.categ_name}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                categ_id:

                                <select name="categ_id" id="categ_id-select" value={editRecord.categ_id} onChange={handleInputChange}>
                                    <option value="">--Select--</option>
                                    {categories.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.category}
                                        </option>
                                    ))}
                                </select>



                            </label>
                        </div>

                        <div>
                            <label>
                                categ_dest:
                                <input
                                    type="text"
                                    name="categ_dest"
                                    value={editRecord.categ_dest}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                details:
                                <input
                                    type="text"
                                    name="details"
                                    value={editRecord.details}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                Subcateg:
                                <input
                                    type="text"
                                    name="subcateg"
                                    value={editRecord.subcateg}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>

                        <div>
                        <label>
                            currency:
                            <select name="currency" id="currency-select" value={editRecord.currency} onChange={handleInputChange}>
                                    <option value="RON">RON</option>
                                    <option value="EUR">EUR</option>
                                </select>


                        </label>
                       </div>

                       <div>
                            <label>
                                Card:
                                <input
                                    type="text"
                                    name="card"
                                    value={editRecord.card}
                                    onChange={handleInputChange}
                                />

                            </label>
                        </div>                       

                        <button type="submit" disabled={isLoading}>
                            {isLoading ? "Saving..." : "Save"}
                        </button>
                        <button type="button" onClick={() => setEditRecord(null)}>
                            Cancel
                        </button>
                        <button type="button" onClick={copyValues}>
                            Clone
                        </button>
                        <button type="button" onClick={deleteRecord}>
                            Delete
                        </button>
                    </form>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
            )}


            <div style={{ marginTop: "20px" }}>
                <h2>Add Record</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAdd();
                    }}
                >
                    <div>
                        <label>
                            data_op:
                            <input
                                type="text"
                                name="data_op"
                                value={form2.data_op}
                                placeholder="YYYY-MM-DD"                                
                                onChange={handleChangeAdd}
                            />
                        </label>
                        <button type="button" onClick={setCurrentDate}>
                            Current Date
                        </button>
                    </div>
                    <div>
                        <label>
                            amount:
                            <input
                                type="text"
                                name="amount"
                                value={form2.amount}
                                onChange={handleChangeAdd}

                            />
                        </label>
                    </div>

                    <div>
                        <label>
                            categ_name:
                            <input
                                type="text"
                                name="categ_name"
                                value={form2.categ_name}
                                onChange={handleChangeAdd}

                            />
                        </label>
                    </div>


                    <div>
                        <label>
                            categ_dest:
                            <input
                                type="text"
                                name="categ_dest"
                                value={form2.categ_dest}

                                onChange={handleChangeAdd}
                            />

                        </label>
                    </div>
                    <div>
                        <label>
                            details:
                            <input
                                type="text"
                                name="details"
                                value={form2.details}
                                onChange={handleChangeAdd}

                            />

                        </label>
                    </div>
                    <div>
                        <label>
                            subcateg:
                            <input
                                type="text"
                                name="subcateg"
                                value={form2.subcateg}
                                onChange={handleChangeAdd}

                            />

                        </label>
                    </div>
                    <div>
                        <label>
                            currency:
                            <input
                                type="text"
                                name="currency"
                                value={form2.currency}
                                onChange={handleChangeAdd}
                            />

                        </label>
                    </div>
                    <div>
                        <label>
                            Card:
                            <input
                                type="text"
                                name="card"
                                value={form2.card}
                                onChange={handleChangeAdd}

                            />
                        </label>
                    </div>


                    <button type="submit" disabled={isLoading}>
                        {isLoading ? "Adding..." : "Add"}
                    </button>
                    <button type="button" onClick={clearAddValues}>
                        Cancel
                    </button>
                </form>
                {error && <p style={{ color: "red" }}>{error}</p>}
            </div>


            <select name="cheltSel" id="chelt-select" onChange={handleSelectChange} >
                <option value="">--Select--</option>
                {categories.map((item) => (
                    <option key={item.id} value={item.id}>{item.id + " : " + item.category}</option>

                ))}

            </select>

            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>data_op</th>
                        <th>amount</th>
                        <th>categ_name</th>
                        <th>categ_id</th>
                        <th>categ_dest</th>
                        <th>details</th>
                        <th>subcateg</th>
                        <th>card</th>
                        <th>currency</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.data_op}</td>
                            <td>{item.amount}</td>
                            <td>{item.categ_name}</td>
                            <td>{item.categ_id}</td>
                            <td>{item.categ_dest}</td>
                            <td>{item.details}</td>
                            <td>{item.subcateg}</td>
                            <td>{item.card}</td>
                            <td>{item.currency}</td>

                            <td> <button onClick={() => handleEditClick(item)}>Edit</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>




        </div>
    );
}


export default MyCosts